import React from 'react'

const BookingFormRatingsCompact = ({ data }) => {
  return (
    <div className="bg-sand-dark overflow-x-auto overflow-y-hidden mb-1 lg:mb-0">
      <div className="flex py-7">
        {data &&
          data.map((item, index) => (
            <div className="flex items-center px-5" key={index}>
              <div className="w-20 md:w-32 h-8 md:h-10 flex items-center justify-center bg-white rounded-full mr-3 md:mr-5">
                <span className="font-semibold text-lg md:text-xl pb-px">
                  {item.rating}
                </span>
              </div>
              <h4 className="font-semibold text-xs md:text-lg">
                {item.source}
              </h4>
            </div>
          ))}
      </div>
    </div>
  )
}

export default BookingFormRatingsCompact
