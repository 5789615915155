import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Layout from 'components/Layout'
import heroPlaceholder from 'images/bg-mountains-a.jpg'

const NotFoundPage = ({ location }) => (
  <Layout metaTitle="404" metaDescription="404" location={location}>
    <img
      className="hidden xl:block w-full"
      src={heroPlaceholder}
      alt="404 page hero"
    />
    <div className="py-15 md:py-32 pb-40 bg-cream">
      <div className="container">
        <div className="text-center">
          <h1 className="text-5xl md:text-6xl xl:text-9xl leading-none mb-10 tracking-wide">
            404
          </h1>
          <div className="leading-snug">
            You just hit a route that doesn&#39;t exist. Would you like to go
            <Link to="/" className="text-teal ml-2 hover:opacity-75">
              Home?
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default NotFoundPage
