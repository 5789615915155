import React from 'react'

const BookingFormRatings = ({ data }) => {
  return (
    <div className="h-full flex items-center justify-center relative z-20 py-16">
      <div className="w-auto overflow-x-auto overflow-y-hidden">
        {data &&
          data.map((item, index) => (
            <div className="flex items-center mb-7 last:mb-0" key={index}>
              <div className="w-32 h-10 flex items-center justify-center bg-white rounded-full mr-7">
                <span className="font-semibold text-xl xl:text-2xl pb-1">
                  {item.rating}
                </span>
              </div>
              <h4 className="font-semibold text-lg xl:text-xl">
                {item.source}
              </h4>
            </div>
          ))}
      </div>
    </div>
  )
}

export default BookingFormRatings
