import React from 'react'

const BookingFormBenefits = () => {
  return (
    <div className="hidden lg:flex flex-wrap items-center -ml-2 -mb-3">
      <div className="pl-2 pb-3 w-full lg:w-1/3 pr-4">
        <div className="flex items-center">
          <div className="flex items-center justify-center w-8 h-8 md:w-12 md:h-12 rounded-full bg-blue flex-shrink-0 mr-4">
            <svg
              className="w-5 h-5 md:w-8 md:h-8 text-white fill-current"
              viewBox="0 0 29.67 22.33"
            >
              <path d="M14.9,15.39a3.47,3.47,0,1,1-3.46,3.47A3.47,3.47,0,0,1,14.9,15.39Zm8.29-4.88L23,10.36l-.16-.15-.09-.07a11.3,11.3,0,0,0-15.61.37l-.5.5-.7.7a2.17,2.17,0,0,0,3.08,3.07l1.2-1.19a7,7,0,0,1,9.86,0l.86.84A2.16,2.16,0,0,0,24,11.37Zm5.9-4.28-.72-.72h0l-.57-.57a1.92,1.92,0,0,0-.32-.26A18.87,18.87,0,0,0,2,5.25a.81.81,0,0,1-.1.09L.58,6.64a2,2,0,0,0,2.8,2.81l1.3-1.3,0,0A14.89,14.89,0,0,1,25.26,8l1,1a2,2,0,0,0,2.81-2.8Z" />
            </svg>
          </div>
          <h4 className="text-xs md:text-lg leading-tight">
            Free WiFi for the duration of your stay
          </h4>
        </div>
      </div>
      <div className="pl-2 pb-3 w-full lg:w-1/3 pr-4">
        <div className="flex items-center">
          <div className="flex items-center justify-center w-8 h-8 md:w-12 md:h-12 rounded-full bg-blue flex-shrink-0 mr-4">
            <svg
              className="w-5 h-5 md:w-7 md:h-7 text-white fill-current"
              viewBox="0 0 24 31"
            >
              <path d="M24,7.76a5.88,5.88,0,0,0-11.72-.83H8.81L7.34.64A.83.83,0,0,0,6.91.09a.77.77,0,0,0-.68,0L.49,2.62a.85.85,0,0,0-.42,1.1.81.81,0,0,0,1.07.43L6,2,7.14,6.93H5.49a.8.8,0,0,0-.59.26.85.85,0,0,0-.22.61L5.77,30.21a.82.82,0,0,0,.81.79H17.05a.82.82,0,0,0,.81-.79l.8-16.43A6,6,0,0,0,24,7.76Zm-6.72.84-.08,1.77H6.43L6.35,8.6Z" />
            </svg>
          </div>
          <h4 className="text-xs md:text-lg leading-tight">
            Complimentary beverages (non-alcoholic)
          </h4>
        </div>
      </div>
      <div className="pl-2 pb-3 w-full lg:w-1/3 pr-4">
        <div className="flex items-center">
          <div className="flex items-center justify-center w-8 h-8 md:w-12 md:h-12 rounded-full bg-blue flex-shrink-0 mr-4">
            <svg
              className="h-4 w-4 md:w-6 md:h-6 text-white fill-current"
              viewBox="0 0 24 24"
            >
              <path d="M9.47,16.8l1.86,1.87L18,12,11.33,5.33,9.47,7.2l3.46,3.47H0v2.66H12.93ZM21.33,0H2.67A2.68,2.68,0,0,0,0,2.67V8H2.67V2.67H21.33V21.33H2.67V16H0v5.33A2.68,2.68,0,0,0,2.67,24H21.33A2.68,2.68,0,0,0,24,21.33V2.67A2.68,2.68,0,0,0,21.33,0Z" />
            </svg>
          </div>
          <h4 className="text-xs md:text-lg leading-tight">
            Access exclusive deals through ‘mia’ the mi–pad app
          </h4>
        </div>
      </div>
    </div>
  )
}

export default BookingFormBenefits
